export default [
  "Booking Submitted",
  "Pending for Approval",
  "Pending for Initial Payment (30% of total amount)",
  "Under Process (Please upload insurance document)",
  "Under Process (Pending to initiate final payment)",
  "Pending for Final Payment",
  "Booking Confirmed",
  "Cancelled (edited)",
];
