<template>
  <Layout>
    <b-tabs v-on:activate-tab="tabChanged" id="booking-tab">
      <b-tab title="Waiting for Approval" active>
        <div style="text-align: end; position: relative; width: 100%">
          <b-dropdown text="All" right variant="outline-primary" class="mt-2">
            <template #button-content>
              <span style="margin-right: 8px"> {{ filter_by_current }}</span>
              <img
                src="@/assets/images/booking/filter.svg"
                alt=""
                srcset=""
                style="width: 14px"
              />
            </template>
            <b-dropdown-item @click="filterBooking(0)"
              >Booking Submitted</b-dropdown-item
            >
            <b-dropdown-item @click="filterBooking(1)"
              >Pending for Approval</b-dropdown-item
            >
            <b-dropdown-item @click="filterBooking(-1)">All</b-dropdown-item>
          </b-dropdown>
        </div>

        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 0" class="row">
            <div
              class="col-xl-4 col-lg-6 col-md-12 col-sm-12"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCard
                userType="admin"
                v-on:loadData="
                  page = 1;
                  loadBooking();
                "
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="In progress">
        <div style="text-align: end; position: relative" id="tab-2-inprogress">
          <b-dropdown variant="outline-primary" right class="mt-2">
            <template #button-content>
              <span style="margin-right: 8px"> {{ filter_by_current }}</span>
              <img
                src="@/assets/images/booking/filter.svg"
                alt=""
                srcset=""
                style="width: 18px"
              />
            </template>
            <b-dropdown-item @click="filterBooking(2)"
              >Pending for Initial Payment</b-dropdown-item
            >

            <b-dropdown-item @click="filterBooking(3)"
              >Waiting for insurance document</b-dropdown-item
            >
            <b-dropdown-item @click="filterBooking(4)"
              >Pending to initiate final payment</b-dropdown-item
            >
            <b-dropdown-item @click="filterBooking(5)"
              >Pending for Final Payment</b-dropdown-item
            >
            <b-dropdown-item @click="filterBooking(-1)">All</b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 1" class="row">
            <div
              class="col-xl-4 col-lg-6 col-md-12 col-sm-12"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCard
                userType="admin"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Confirmed">
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 2" class="row">
            <div
              class="col-xl-4 col-lg-6 col-md-12 col-sm-12"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCard
                userType="admin"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Cancelled">
        <div v-if="loading" style="text-align: center; margin-top: 28px">
          <pulse-loader :loading="loading"></pulse-loader>
        </div>
        <div v-else>
          <div v-if="tab == 3" class="row">
            <div
              class="col-xl-4 col-lg-6 col-md-12 col-sm-12"
              v-for="item in data"
              :key="item._id"
            >
              <BookingCard
                userType="admin"
                v-on:loadData="loadBooking"
                :data="item"
              />
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div v-if="data.length" class="d-flex justify-content-end">
      <b-pagination
        :value="page"
        @change="pageChange"
        :total-rows="rows"
        :per-page="perPage"
        pills
      ></b-pagination>
    </div>
    <div v-else style="text-align: center; margin-top: 18px">
      <span v-if="!loading"> No Data </span>
    </div>
  </Layout>
</template>

<script>
import BookingCard from "@/components/booking_card/BookingCard";
import Layout from "../../layouts/main";
import bookingStatus from "@/data/bookingStatus";
import { booking } from "@/config/api/booking";
export default {
  data() {
    return {
      data: {},
      loading: false,
      tab: 0,
      rows: 0,
      page: 1,
      perPage: 12,
      filter: false,
      filter_by: -1,
      bookingStatus: bookingStatus,
    };
  },
  components: {
    Layout,
    BookingCard,
  },
  computed: {
    filter_by_current() {
      return this.filter_by < 0 ? "All" : bookingStatus[this.filter_by];
    },
  },
  created() {
    this.loadBooking();
  },
  methods: {
    tabChanged(val) {
      this.resetFilter();
      this.tab = val;
      this.page = 1;
      this.loadBooking();
    },
    loadBooking() {
      const api = booking.getBookingsForAdmin;
      api.params = {
        perPage: this.perPage,
        page: this.page,
      };
      this.filter
        ? (api.params.status = this.filter_by)
        : (api.params.status_range = this.tab),
        (this.loading = true);
      this.generateAPI(api)
        .then((res) => {
          this.data = res.data.docs;
          this.rows = res.data.totalDocs;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageChange(val) {
      this.page = val;
      this.loadBooking();
    },
    filterBooking(val) {
      if (val == -1) {
        this.resetFilter();
      } else {
        this.filter = true;
        this.filter_by = val;
      }
      this.page = 1;
      this.loadBooking();
    },
    resetFilter() {
      this.filter = false;
      this.filter_by = -1;
    },
  },
};
</script>

<style>
</style>
