<template>
  <div class="traveller-card-wrapper">
    <div class="row">
      <div class="col-4 indicator pt-1 text-center">
        <img
          src="@/assets/images/booking/Profile 2user.png"
          alt=""
          srcset=""
        /><br />
        <strong>Travellers</strong>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-6 traveller-count">
            {{ totalTraveller }}
          </div>
          <div class="col-6" v-if="data.travellers.length > 0">
            <button @click="openModal">View All</button>
          </div>
        </div>
      </div>
    </div>
    <b-modal :id="`traveller${data.id}`" title="Travellers">
      <b-card
        class="m-2 traveller-card"
        v-for="traveller in data.travellers"
        :key="traveller._id"
      >
        <div class="row p-0 m-0" v-if="data.travellers.length > 0">
          <div
            class="col-2 p-0 m-0"
            style="background-color: #c8d5db; border-radius: 12px"
          >
            <img
              style="margin-top: 8px"
              src="@/assets/images/booking/Profile.svg"
              alt=""
              srcset=""
            />
          </div>
          <div class="col-10 traveller-details">
            <div>
              <i class="ri-user-line"></i>

              <strong>
                {{ traveller.first_name }} {{ traveller.last_name }}
              </strong>
            </div>

            <div>
              <i class="ri-mail-line"></i>
              <span>
                {{ traveller.email_address }}
              </span>
            </div>

            <div>
              <i class="ri-smartphone-line"></i>
              <span>
                {{ traveller.phone_number }}
              </span>
            </div>

            <div v-if="traveller.document">
              <i class="ri-profile-line"></i>

              {{ traveller.passport_number }}
              <a
                :href="traveller.document"
                class="passport-view-btn"
                target="_blank"
              >
                <i class="ri-download-line"></i><span> View</span>
              </a>
            </div>
            <div v-if="traveller.insurance">
              <i class="ri-profile-line"></i>
              Insurance
              <a
                :href="traveller.insurance"
                class="passport-view-btn"
                target="_blank"
              >
                <i class="ri-download-line"></i><span> View</span>
              </a>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["data"],

  computed: {
    totalTraveller() {      
      return this.data.travellersCount;
    },
  },
  data() {
    return {};
  },
  methods: {
    openModal() {
      this.$bvModal.show(`traveller${this.data.id}`);
    },
  },
};
</script>


<style>
.traveller-card-wrapper {
  background-color: #f1f5f7;
  color: black;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  text-align: center;
}

.traveller-card-wrapper img {
  width: 28px;
}

.traveller-count {
  font-size: 28px;
  font-weight: 900;
  font-family: sans-serif;
}

.traveller-card-wrapper button {
  background-color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 900;
  margin-right: 8px;
  border-radius: 8px;
  margin-top: 10px;
}

.traveller-card-wrapper .indicator {
  background-color: #c8d5db;
  font-size: 12px;
}
.traveller-card {
  background-color: #f1f5f7;
}
.traveller-details div {
  display: flex;
}
.traveller-details div i {
  margin-right: 8px;
  color: #000;
}
.passport-view-btn {
  background-color: #fff;
  color: #000;
  padding: 1px 8px;
  font-size: 11px;
  margin-left: 14px;
  border-radius: 8px;
  display: inline-flex;
  margin-top: 2px;
}
.passport-view-btn:hover {
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}
</style>