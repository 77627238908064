<template>
  <div class="payment-card-wrapper">
    <div class="row p-0">
      <div class="col-4 indicator pt-3">
        <i style="font-size:32px;color:white" class="ri-money-dollar-circle-line"></i><br />
        Payment
      </div>
      <div class="col-4 p-0">
        <ul class="payment-status">
          <li>
            <span v-if="data.booking_payment_status == 'Pending'">
              <i class="ri-checkbox-blank-circle-fill" style="color: gray"></i>
            </span>
            <span v-else>
              <i class="ri-checkbox-blank-circle-fill" style="color: gray"
                v-if="data.booking_payment_status != 'PAID' && data.booking_payment_status != 'Pending'"></i>
              <i class="ri-time-fill" style="color: orange" v-if="data.booking_payment_status == 'Pending'"></i>
              <i class="ri-checkbox-circle-fill" style="color: green" v-if="data.booking_payment_status == 'PAID'"></i>
            </span>

            Payment
          </li>

        </ul>
      </div>
      <div class="col-4 text-center">
        <div class="total-amount mt-2">
          Total Amount <br />
          <strong style="font-size: 24px; font-weight: 900; font-family: sans-serif">
            {{ data.amount }}</strong>
          USD
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { booking } from "@/config/api/booking";
export default {
  props: ["data"],
  data() {
    return {
      initiatingSecondPayment: false,
    };
  },
  methods: {
    initateSecondPayment(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure, do you want to initiate second payment?",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const api = booking.initSecondPayment;
            api.params = {
              booking_id: id,
            };
            this.initiatingSecondPayment = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast(
                  "Booking second payment initiation success.",
                  {
                    title: "Booking Second Payment",
                    variant: "success",
                    solid: true,
                  }
                );
                this.$emit("loadData");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Booking second payment initiation failed with the error ${err.response.data.message}`,
                  {
                    title: "Booking Second Payment",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.initiatingSecondPayment = false;
              });
          }
        });
    },
  },
};
</script>

<style>
sup {
  top: 8px;
}

.payment-card-wrapper {
  background-color: #f1f5f7;
  color: black;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
}

.payment-card-wrapper .indicator {
  background-color: #c8d5db;
  padding: 8px;
  text-align: center;
}

.payment-card-wrapper img {
  width: 28px;
}

.payment-card .total-amount {
  background-color: #c8d5db;
  padding: 14px;
}

.total-amount strong {
  font-size: 22px !important;
}

.payment-status {
  list-style: none;
  padding: 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
}

.payment-status li {
  display: flex;
  font-size: 14px;
}

.payment-status li i {
  margin-right: 6px;
}

.payment-init-btn {
  display: block;
  width: 100%;
  padding: 0px;
  border: none;
  text-align: center;
  background-color: #fff;
  padding: 2px 4px;

  border-radius: 12px;
}

.payment-init-btn img {
  width: 18px;
  height: 18px;
}

.payment-init-btn:hover {
  background-color: #fafafa;
  scale: 1.1;
}
</style>
